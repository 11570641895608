









































































































































import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { User } from '@/types/user'
import { vxm } from '@/store'

@Component
export default class extends Vue {
  private onLoad = true
  private allUsers: Partial<User>[] = []
  private headers = [
    { text: 'Image', value: 'profilePicture', align: 'center' },
    { text: 'ID', value: 'id', align: 'center' },
    { text: 'Username', value: 'username', align: 'center' },
    { text: 'Firstname', value: 'firstName', align: 'center' },
    { text: 'Lastname', value: 'lastName', align: 'center' },
    { text: 'Nickname', value: 'nickname', align: 'center' },
    { text: 'FirstnameEn', value: 'firstNameEn', align: 'center' },
    { text: 'LastnameEn', value: 'lastNameEn', align: 'center' },
    { text: 'NicknameEn', value: 'nicknameEn', align: 'center' },
    { text: 'E-Mail', value: 'email', align: 'center' },
    { text: 'Phone', value: 'phone', align: 'center' },
    { text: 'Roles', value: 'roles', align: 'center' },
    { text: 'Actions', value: 'actions', align: 'center' },
  ]
  private uploadDialog = false
  private selectedUser = ''
  private files: File[] = []
  private showPreview = false
  private uploading = false

  mounted() {
    this.fetchUsers()
  }

  private async fetchUsers() {
    this.onLoad = true
    try {
      const res = await this.$apollo.query<{ users: Partial<User>[] }>({
        query: gql`
          query GetAllUsers {
            users(position: staff) {
              id
              username
              position
              firstName
              lastName
              nickname
              firstNameEn
              lastNameEn
              nicknameEn
              email
              phone
              roles
              profilePicture {
                contentType
                signedUrl
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      this.allUsers = res.data.users.sort((a, b) => {
        if ((a.username || '') > (b.username || '')) {
          return 1
        }
        return -1
      })
    } catch (error) {
      // empty
    } finally {
      this.onLoad = false
    }
  }

  private showUploadDialog(el: number) {
    this.selectedUser = `${el}`
    this.uploadDialog = true
  }

  private closeUploadDialog() {
    this.selectedUser = ''
    this.files = []
    this.showPreview = false
    this.uploadDialog = false
  }

  private generatePreview() {
    if (this.files.length > 0) {
      const cv = this.$refs.previewCanvas as HTMLCanvasElement
      cv.width = 1050
      cv.height = 1050
      const ctx = cv.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, cv.width, cv.height)
        const img = new Image()
        img.src = URL.createObjectURL(this.files[0])
        img.onload = () => {
          const size = Math.min(img.width, img.height)
          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            1050,
            1050
          )
          this.showPreview = true
        }
      }
    } else {
      this.showPreview = false
    }
  }

  private async uploadImage() {
    this.uploading = true
    const cv = this.$refs.previewCanvas as HTMLCanvasElement
    cv.toBlob(
      b => {
        if (b) {
          const form = new FormData()
          form.append('file', b)
          this.axios.post(
            `${process.env.VUE_APP_OFFICE_URL}/user/${this.selectedUser}/picture`,
            form,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${vxm.login.getToken}`,
              },
            }
          )
          const thisUser = this.allUsers.find(
            el => `${el.id ? el.id : '-'}` === this.selectedUser
          )
          if (thisUser) {
            thisUser.profilePicture = {
              contentType: 'image/jpeg',
              signedUrl: cv.toDataURL(),
            }
          }
          this.uploading = false
          this.closeUploadDialog()
        } else {
          this.uploading = false
          this.closeUploadDialog()
        }
      },
      'image/jpeg',
      0.95
    )
  }
}
